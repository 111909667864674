.Type-Box {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 98%;
  height: 25%;
  background: rgb(48, 60, 68);
  border: 2px solid rgba(170, 170, 170, 0.596);
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.txtarea {
  width: 90%;
  height: 86%;
  padding: 1%;
  resize: none;
  border: none;
  background: rgb(48, 60, 68);
  color: white;
  outline: none;

  margin-left: 0;
}

@media only screen and (max-width: 800px) {
  .Type-Box {
    margin: auto;
    width: 99%;
  }
}
