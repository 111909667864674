.tab-space {
  margin-top: 0.6%;
  display: flex;
  width: 99%;
  overflow: hidden;
}

.friend-tab {
  display: flex;
  box-shadow: 2px 2px 1px 2px rgba(0, 0, 0, 0.418);
  cursor: pointer;
  padding: 0.4%;
  justify-content: center;
  align-content: center;
  border: 2px solid black;
  border-radius: 8px 10px 4px 0px;
  height: 100%;
  color: white;
  background-color: #3d2d53;
  margin-left: 1%;
  z-index: 100;
}
