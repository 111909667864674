.outer-signup {
  width: 98%;
  margin: auto;
  background-color: rgba(44, 116, 211, 0.171);
  height: 5%;
  margin-bottom: 1%;
  padding: 1%;
}
.sign-up-form {
  display: flex;
  flex-direction: row;
  width: 60%;
  height: 100%;
  margin: auto;
}
@media all and (max-width: 1000px) {
  .sign-up-form {
    width: 93%;
  }
}
.inp {
  align-content: center;
  height: 90%;
  margin: auto;
  border: none;
  border-right: 1px solid black;
  width: 35%;
  text-align: center;
  border-radius: 5px;
}

.sign-up-btn {
  background-color: rgba(85, 111, 226, 0.753);
  border: 0.5px solid rgb(0, 0, 0);
  border: none;
  border-right: 1px solid black;
  height: 90%;
  margin: auto;
  width: 16%;
  font-weight: bold;
  color: white;
}

.sign-up-btn:hover {
  cursor: pointer;
  background-color: rgba(68, 90, 189, 0.897);
}
.cancel-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(221, 85, 85, 0.795);
  border: 0.5px solid rgb(0, 0, 0);
  border: none;
  border-right: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  font-size: 20px;
  color: rgba(65, 0, 0, 0.747);
}

.cancel-btn:hover {
  cursor: pointer;
  background: rgba(167, 63, 63, 0.795);
  color: rgba(65, 0, 0, 0.747);
}

.cancel-btn:active {
  border: none;
}
