.Friend-Box {
  display: inline-block;
  position: relative;
  margin: 1%;
  margin-top: 0;
  text-align: center;
  border-radius: 8px;
  width: 98%;
  height: 100%;
  background: rgba(43, 40, 54, 0.411);
  overflow: hidden;
}
.hide-friends {
  width: 100%;
  margin: auto;
}
.hide-bar {
  display: none;
}

.friend {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.13);
  height: 3%;
  background: rgba(121, 218, 221, 0.219);
  border-top: 0;
  padding: 5px;
  color: white;
}
.friend-label {
  width: 100%;
  opacity: 0.9;
  text-shadow: 1px 2px black;
  border-bottom: 2px solid rgba(0, 0, 0, 0.13);
  height: 3%;
  background: rgba(148, 187, 160, 0.63);
  padding: 5px;
  color: white;
}

.usr-btn {
  cursor: pointer;
}

.chosen {
  width: 94%;
  border: 2px solid rgba(0, 0, 0, 0.438);
  height: 3%;
  background: rgba(100, 194, 197, 0.664);
  padding: 5px;
  color: white;
}

/* For PHONES */
@media only screen and (max-width: 800px) {
  .Friend-Box {
    position: relative;
    margin: 1%;
    float: right;
    width: 100%;
    height: 87%;
    background: rgb(43, 40, 54);
  }
  .Friend-Overlay {
    position: absolute;
    width: 100%;
    background: blue;
    height: 100%;
    z-index: 1100;
  }
}
