body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: rgba(30, 31, 44, 0.849);
}

.App {
  height: 100vh;
  /* overflow: hidden; */
  width: 100%;
  max-width: 1500px;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.navbar-outer {
  width: 100%;
}

.left-section {
  display: -webkit-flex;
  display: flex;
  width: 75%;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  -webkit-transition: width 0.9s ease-in-out;
  transition: width 0.9s ease-in-out;
}

.right-tall-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25%;
  margin: 0;
  -webkit-transition: width 0.9s ease-in-out;
  transition: width 0.9s ease-in-out;
}

.wide-left {
  width: 100%;
  -webkit-transition: width 0.9s ease-in-out;
  transition: width 0.9s ease-in-out;
}

.thin-right {
  -webkit-transition: width 0.9s ease-in-out;
  transition: width 0.9s ease-in-out;

  width: 0%;
}

.mid-box-main {
  background: rgba(37, 37, 179, 0);
  height: 100%;
  width: 98%;
  /* padding: 1%; */
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

@media only screen and (max-width: 800px) {
  .left-section {
    width: 100%;
  }

  .right-tall-bar {
    width: 0px;
    display: hidden;
  }

  .friend-hide {
    width: 0;
  }
  .friend-show {
    width: 100%;
  }
}

.NavBar {
  max-width: 100%;
  height: 10vh;
  margin: auto;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  background: rgb(36, 88, 112);
}
.login-msg {
  color: white;
  float: right;
  margin: 1%;
}

.nav-btns {
  height: 100%;
  width: 100%;
}

.navitem1 {
  float: right;
  position: relative;
  background: rgba(35, 30, 104, 0.562);
  border: rgba(255, 255, 255, 0);
  color: white;
  height: 100%;
  width: 90px;
  margin-right: 4%;
  z-index: 50;
  border-radius: 10px;
}

.navitem1:hover {
  background: rgb(54, 23, 33);
  cursor: pointer;
}

.navitem1::active {
  background: rgb(82, 47, 58);
  cursor: pointer;
}
.logo {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 3px 2px 2px rgba(24, 77, 83, 0.926);
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  font-family: "Laila", serif;
  max-height: 100%;
  z-index: 30;
}

.svg-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-right: 10%;
  color: rgb(22, 175, 81);
  height: 100%;
  font-size: 60px;
  z-index: 20;
}

.backtitle {
  position: absolute;
  background: rgba(51, 51, 109, 0.63);
  color: white;
  height: 70%;
  border-radius: 3px;
  box-shadow: 3px 3px 2px 3px rgb(0, 0, 0);
  box-shadow: 0px 0px 5px 2px rgba(176, 172, 180, 0.122);
  cursor: pointer;
  width: 30%;
  border: none;
  z-index: 10;
}

.logout-container {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 80;
  height: 100%;
}

.logout-btn {
  position: relative;
  background: rgba(16, 16, 68, 0.644);
  color: white;
  height: 70%;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
  border: none;
  z-index: 80;
  margin-left: 50%;
  margin-right: 4%;
}

.logout-btn:hover {
  background: rgba(21, 21, 121, 0.644);
  cursor: pointer;
  z-index: 20;
}

.friend-burger {
  float: left;
  display: none;
  margin-left: 1%;
  cursor: pointer;
  color: white;
  position: absolute;
  display: absolute;
  font-size: 46px;
}

/* For PHONES */
@media only screen and (max-width: 800px) {

  .NavBar {
  max-width: 100%;
  height: 10vh;
  margin: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgb(36, 88, 112);
  z-index: 1;
}
  .friend-burger {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 4%;
    cursor: pointer;
    color: white;
    position: relative;
    font-size: 46px;
    z-index: 1000;
    margin-right: 60%;
  }

  .friend-burger:hover {
    color: rgb(236, 236, 236);
  }
  .friend-burger:active {
    color: rgb(165, 207, 218);
  }

  .logo {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 3px 2px 2px rgba(24, 77, 83, 0.926);
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  font-family: "Laila", serif;
  max-height: 100%;
  z-index: 30;
}

.svg-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-right: 10%;
  color: rgb(22, 175, 81);
  height: 100%;
  font-size: 60px;
  z-index: 20;
}

.backtitle {
  position: absolute;
  background: rgba(51, 51, 109, 0.63);
  color: white;
  height: 70%;
  border-radius: 3px;
  box-shadow: 3px 3px 2px 3px rgb(0, 0, 0);
  box-shadow: 0px 0px 5px 2px rgba(176, 172, 180, 0.122);
  cursor: pointer;
  width: 30%;
  border: none;
  z-index: 10;
}
.navitem1 {
  float: right;
  position: relative;
  background: rgba(35, 30, 104, 0.562);
  border: rgba(255, 255, 255, 0);
  color: white;
  height: 100%;
  font-size: 10px;
  width: 50px;
  margin-right: 5px;
  z-index: 100;
  border-radius: 10px;
}

.nav-btns {
  height: 100%;
  width: 50%;
  margin-left: 50%;
  z-index: 100;
}
}

.Friend-Box {
  display: inline-block;
  position: relative;
  margin: 1%;
  margin-top: 0;
  text-align: center;
  border-radius: 8px;
  width: 98%;
  height: 100%;
  background: rgba(43, 40, 54, 0.411);
  overflow: hidden;
}
.hide-friends {
  width: 100%;
  margin: auto;
}
.hide-bar {
  display: none;
}

.friend {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.13);
  height: 3%;
  background: rgba(121, 218, 221, 0.219);
  border-top: 0;
  padding: 5px;
  color: white;
}
.friend-label {
  width: 100%;
  opacity: 0.9;
  text-shadow: 1px 2px black;
  border-bottom: 2px solid rgba(0, 0, 0, 0.13);
  height: 3%;
  background: rgba(148, 187, 160, 0.63);
  padding: 5px;
  color: white;
}

.usr-btn {
  cursor: pointer;
}

.chosen {
  width: 94%;
  border: 2px solid rgba(0, 0, 0, 0.438);
  height: 3%;
  background: rgba(100, 194, 197, 0.664);
  padding: 5px;
  color: white;
}

/* For PHONES */
@media only screen and (max-width: 800px) {
  .Friend-Box {
    position: relative;
    margin: 1%;
    float: right;
    width: 100%;
    height: 87%;
    background: rgb(43, 40, 54);
  }
  .Friend-Overlay {
    position: absolute;
    width: 100%;
    background: blue;
    height: 100%;
    z-index: 1100;
  }
}

.sendBar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin-right: 1%;
  width: 60px;
  height: 100%;
  text-align: center;
  color: rgba(39, 113, 182, 0.747);
  cursor: pointer;
}

.attach-btn {
  height: 30%;
  width: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-shadow: 3px 2px 2px rgba(3, 3, 3, 0.389);
  border-radius: 40px;
  font-size: 40px;
  color: rgba(39, 113, 182, 0.747);
  margin-bottom: 20%;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.attach-btn:hover {
  color: rgb(28, 214, 69);
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.send-btn {
  height: 30%;
  width: 60px;
  /* transform: scaleX(-1); */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-shadow: 3px 2px 2px rgba(3, 3, 3, 0.389);
  border-radius: 40px;
  font-size: 40px;
  color: rgba(39, 113, 182, 0.747);
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.send-btn:hover {
  color: rgb(28, 214, 69);
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.send-btn.show {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.Type-Box {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  width: 98%;
  height: 25%;
  background: rgb(48, 60, 68);
  border: 2px solid rgba(170, 170, 170, 0.596);
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.txtarea {
  width: 90%;
  height: 86%;
  padding: 1%;
  resize: none;
  border: none;
  background: rgb(48, 60, 68);
  color: white;
  outline: none;

  margin-left: 0;
}

@media only screen and (max-width: 800px) {
  .Type-Box {
    margin: auto;
    width: 99%;
  }
}

.Chat-Box {
  width: 98%;
  height: 74%;
  background: rgb(27, 31, 36);
  overflow: auto;
  -webkit-scroll-snap-type: proximity;
      -ms-scroll-snap-type: proximity;
          scroll-snap-type: proximity;
  border-radius: 5px;
  border: 2px solid rgba(170, 170, 170, 0.596);
  border-radius: 5px;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.text-boxes {
  border: 0.5px solid rgba(0, 0, 0, 0.356);
  width: 40%;
  background: rgba(63, 255, 245, 0.39);
  margin-left: 1%;
  margin-top: 1%;
  padding: 1%;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
}

.text-boxes:hover {
  border: 0.5px solid rgba(33, 42, 82, 0.356);
  background: rgba(49, 231, 222, 0.753);
}

.text-boxes-user {
  border: 0.5px solid rgba(0, 0, 0, 0.356);
  width: 40%;
  background: rgba(255, 92, 63, 0.39);
  margin: auto;
  margin-right: 1%;
  margin-top: 1%;
  padding: 1%;
  border-radius: 10px;
  border-top-right-radius: 0px;
  margin-bottom: 1%;
}

.text-boxes-user:hover {
  border: 0.5px solid rgba(33, 42, 82, 0.356);
  background: rgba(223, 102, 46, 0.753);
}

.signIn-message {
  position: relative;
  color: white;
  padding: 4%;
  border-radius: 30px;
  height: 50%;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  background: rgba(4, 226, 255, 0.384);
  box-shadow: 0px 0px 10px 7px rgba(75, 81, 160, 0.322);
}
.hey-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
.bolder {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: rgb(191, 219, 255);
}
.bolder:hover {
  color: red;
}
.hey-small {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  background: rgba(0, 140, 255, 0.047);
  border-radius: 20px;
}

.selectUser-msg {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 24px;
}

.arrow {
  margin: 0% 3%;
  margin-top: 10px;
  font-size: 40px;
  color: rgb(255, 179, 179);
}

.loading {
  color: white;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  margin-top: 2%;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(38, 99, 139, 0.5);
}

/* For PHONES */
@media only screen and (max-width: 800px) {
  .Chat-Box {
    display: inline-block;
    position: relative;
    margin: auto;
    width: 100%;
    height: 64vh;
    background: rgb(27, 31, 36);
    overflow: auto;
    -webkit-scroll-snap-type: proximity;
        -ms-scroll-snap-type: proximity;
            scroll-snap-type: proximity;
  }
  .arrow {
    -webkit-transform: translate(-200px, -70px) rotate(-110deg);
            transform: translate(-200px, -70px) rotate(-110deg);
    font-size: 50px;
  }
  .selectUser-msg {
    width: 100%;
    font-size: 20px;
  }
}

.outer-signup {
  width: 98%;
  margin: auto;
  background-color: rgba(44, 116, 211, 0.171);
  height: 5%;
  margin-bottom: 1%;
  padding: 1%;
}
.sign-up-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 60%;
  height: 100%;
  margin: auto;
}
@media all and (max-width: 1000px) {
  .sign-up-form {
    width: 93%;
  }
}
.inp {
  -webkit-align-content: center;
          align-content: center;
  height: 90%;
  margin: auto;
  border: none;
  border-right: 1px solid black;
  width: 35%;
  text-align: center;
  border-radius: 5px;
}

.sign-up-btn {
  background-color: rgba(85, 111, 226, 0.753);
  border: 0.5px solid rgb(0, 0, 0);
  border: none;
  border-right: 1px solid black;
  height: 90%;
  margin: auto;
  width: 16%;
  font-weight: bold;
  color: white;
}

.sign-up-btn:hover {
  cursor: pointer;
  background-color: rgba(68, 90, 189, 0.897);
}
.cancel-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(221, 85, 85, 0.795);
  border: 0.5px solid rgb(0, 0, 0);
  border: none;
  border-right: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  font-size: 20px;
  color: rgba(65, 0, 0, 0.747);
}

.cancel-btn:hover {
  cursor: pointer;
  background: rgba(167, 63, 63, 0.795);
  color: rgba(65, 0, 0, 0.747);
}

.cancel-btn:active {
  border: none;
}

.sign-in-outer {
  width: 98%;
  margin: auto;
  background-color: rgba(44, 116, 211, 0.171);
  height: 5%;
  margin-bottom: 1%;
  padding: 1%;
}
.sign-in-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 60%;
  height: 100%;
  margin: auto;
}
@media all and (max-width: 1000px) {
  .sign-in-form {
    width: 93%;
  }
}
.inp {
  -webkit-align-content: center;
          align-content: center;
  height: 90%;
  margin: auto;
  border: none;
  border-right: 1px solid black;
  width: 35%;
  text-align: center;
  border-radius: 5px;
}

.sign-in-btn {
  background-color: rgba(85, 111, 226, 0.753);
  border: 0.5px solid rgb(0, 0, 0);
  border: none;
  border-right: 1px solid black;
  height: 90%;
  margin: auto;
  width: 16%;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.sign-in-btn:hover {
  cursor: pointer;
  background-color: rgba(68, 90, 189, 0.897);
}

.cancel-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(221, 85, 85, 0.795);
  border: 0.5px solid rgb(0, 0, 0);
  border: none;
  border-right: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  font-size: 20px;
  color: rgba(65, 0, 0, 0.747);
}

.cancel-btn:hover {
  cursor: pointer;
  background: rgba(167, 63, 63, 0.795);
  color: rgba(65, 0, 0, 0.747);
}

.cancel-btn:active {
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tab-space {
  margin-top: 0.6%;
  display: -webkit-flex;
  display: flex;
  width: 99%;
  overflow: hidden;
}

.friend-tab {
  display: -webkit-flex;
  display: flex;
  box-shadow: 2px 2px 1px 2px rgba(0, 0, 0, 0.418);
  cursor: pointer;
  padding: 0.4%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  border: 2px solid black;
  border-radius: 8px 10px 4px 0px;
  height: 100%;
  color: white;
  background-color: #3d2d53;
  margin-left: 1%;
  z-index: 100;
}

