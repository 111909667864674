.NavBar {
  max-width: 100%;
  height: 10vh;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgb(36, 88, 112);
}
.login-msg {
  color: white;
  float: right;
  margin: 1%;
}

.nav-btns {
  height: 100%;
  width: 100%;
}

.navitem1 {
  float: right;
  position: relative;
  background: rgba(35, 30, 104, 0.562);
  border: rgba(255, 255, 255, 0);
  color: white;
  height: 100%;
  width: 90px;
  margin-right: 4%;
  z-index: 50;
  border-radius: 10px;
}

.navitem1:hover {
  background: rgb(54, 23, 33);
  cursor: pointer;
}

.navitem1::active {
  background: rgb(82, 47, 58);
  cursor: pointer;
}
.logo {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 3px 2px 2px rgba(24, 77, 83, 0.926);
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  font-family: "Laila", serif;
  max-height: 100%;
  z-index: 30;
}

.svg-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-right: 10%;
  color: rgb(22, 175, 81);
  height: 100%;
  font-size: 60px;
  z-index: 20;
}

.backtitle {
  position: absolute;
  background: rgba(51, 51, 109, 0.63);
  color: white;
  height: 70%;
  border-radius: 3px;
  box-shadow: 3px 3px 2px 3px rgb(0, 0, 0);
  box-shadow: 0px 0px 5px 2px rgba(176, 172, 180, 0.122);
  cursor: pointer;
  width: 30%;
  border: none;
  z-index: 10;
}

.logout-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 80;
  height: 100%;
}

.logout-btn {
  position: relative;
  background: rgba(16, 16, 68, 0.644);
  color: white;
  height: 70%;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
  border: none;
  z-index: 80;
  margin-left: 50%;
  margin-right: 4%;
}

.logout-btn:hover {
  background: rgba(21, 21, 121, 0.644);
  cursor: pointer;
  z-index: 20;
}

.friend-burger {
  float: left;
  display: none;
  margin-left: 1%;
  cursor: pointer;
  color: white;
  position: absolute;
  display: absolute;
  font-size: 46px;
}

/* For PHONES */
@media only screen and (max-width: 800px) {

  .NavBar {
  max-width: 100%;
  height: 10vh;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: rgb(36, 88, 112);
  z-index: 1;
}
  .friend-burger {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
    cursor: pointer;
    color: white;
    position: relative;
    font-size: 46px;
    z-index: 1000;
    margin-right: 60%;
  }

  .friend-burger:hover {
    color: rgb(236, 236, 236);
  }
  .friend-burger:active {
    color: rgb(165, 207, 218);
  }

  .logo {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 3px 2px 2px rgba(24, 77, 83, 0.926);
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  font-family: "Laila", serif;
  max-height: 100%;
  z-index: 30;
}

.svg-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-right: 10%;
  color: rgb(22, 175, 81);
  height: 100%;
  font-size: 60px;
  z-index: 20;
}

.backtitle {
  position: absolute;
  background: rgba(51, 51, 109, 0.63);
  color: white;
  height: 70%;
  border-radius: 3px;
  box-shadow: 3px 3px 2px 3px rgb(0, 0, 0);
  box-shadow: 0px 0px 5px 2px rgba(176, 172, 180, 0.122);
  cursor: pointer;
  width: 30%;
  border: none;
  z-index: 10;
}
.navitem1 {
  float: right;
  position: relative;
  background: rgba(35, 30, 104, 0.562);
  border: rgba(255, 255, 255, 0);
  color: white;
  height: 100%;
  font-size: 10px;
  width: 50px;
  margin-right: 5px;
  z-index: 100;
  border-radius: 10px;
}

.nav-btns {
  height: 100%;
  width: 50%;
  margin-left: 50%;
  z-index: 100;
}
}
