html {
  background: rgba(30, 31, 44, 0.849);
}

.App {
  height: 100vh;
  /* overflow: hidden; */
  width: 100%;
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.navbar-outer {
  width: 100%;
}

.left-section {
  display: flex;
  width: 75%;
  flex-direction: column;
  overflow: hidden;
  transition: width 0.9s ease-in-out;
}

.right-tall-bar {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0;
  transition: width 0.9s ease-in-out;
}

.wide-left {
  width: 100%;
  transition: width 0.9s ease-in-out;
}

.thin-right {
  transition: width 0.9s ease-in-out;

  width: 0%;
}

.mid-box-main {
  background: rgba(37, 37, 179, 0);
  height: 100%;
  width: 98%;
  /* padding: 1%; */
  margin: auto;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 800px) {
  .left-section {
    width: 100%;
  }

  .right-tall-bar {
    width: 0px;
    display: hidden;
  }

  .friend-hide {
    width: 0;
  }
  .friend-show {
    width: 100%;
  }
}
