.Chat-Box {
  width: 98%;
  height: 74%;
  background: rgb(27, 31, 36);
  overflow: auto;
  scroll-snap-type: proximity;
  border-radius: 5px;
  border: 2px solid rgba(170, 170, 170, 0.596);
  border-radius: 5px;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.text-boxes {
  border: 0.5px solid rgba(0, 0, 0, 0.356);
  width: 40%;
  background: rgba(63, 255, 245, 0.39);
  margin-left: 1%;
  margin-top: 1%;
  padding: 1%;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
}

.text-boxes:hover {
  border: 0.5px solid rgba(33, 42, 82, 0.356);
  background: rgba(49, 231, 222, 0.753);
}

.text-boxes-user {
  border: 0.5px solid rgba(0, 0, 0, 0.356);
  width: 40%;
  background: rgba(255, 92, 63, 0.39);
  margin: auto;
  margin-right: 1%;
  margin-top: 1%;
  padding: 1%;
  border-radius: 10px;
  border-top-right-radius: 0px;
  margin-bottom: 1%;
}

.text-boxes-user:hover {
  border: 0.5px solid rgba(33, 42, 82, 0.356);
  background: rgba(223, 102, 46, 0.753);
}

.signIn-message {
  position: relative;
  color: white;
  padding: 4%;
  border-radius: 30px;
  height: 50%;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  background: rgba(4, 226, 255, 0.384);
  box-shadow: 0px 0px 10px 7px rgba(75, 81, 160, 0.322);
}
.hey-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
.bolder {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: rgb(191, 219, 255);
}
.bolder:hover {
  color: red;
}
.hey-small {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  background: rgba(0, 140, 255, 0.047);
  border-radius: 20px;
}

.selectUser-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 24px;
}

.arrow {
  margin: 0% 3%;
  margin-top: 10px;
  font-size: 40px;
  color: rgb(255, 179, 179);
}

.loading {
  color: white;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  margin-top: 2%;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(38, 99, 139, 0.5);
}

/* For PHONES */
@media only screen and (max-width: 800px) {
  .Chat-Box {
    display: inline-block;
    position: relative;
    margin: auto;
    width: 100%;
    height: 64vh;
    background: rgb(27, 31, 36);
    overflow: auto;
    scroll-snap-type: proximity;
  }
  .arrow {
    transform: translate(-200px, -70px) rotate(-110deg);
    font-size: 50px;
  }
  .selectUser-msg {
    width: 100%;
    font-size: 20px;
  }
}
