.sendBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-right: 1%;
  width: 60px;
  height: 100%;
  text-align: center;
  color: rgba(39, 113, 182, 0.747);
  cursor: pointer;
}

.attach-btn {
  height: 30%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 3px 2px 2px rgba(3, 3, 3, 0.389);
  border-radius: 40px;
  font-size: 40px;
  color: rgba(39, 113, 182, 0.747);
  margin-bottom: 20%;
  transition: color 0.3s ease-in-out;
}

.attach-btn:hover {
  color: rgb(28, 214, 69);
  transition: color 0.3s ease-in-out;
}

.send-btn {
  height: 30%;
  width: 60px;
  /* transform: scaleX(-1); */
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 3px 2px 2px rgba(3, 3, 3, 0.389);
  border-radius: 40px;
  font-size: 40px;
  color: rgba(39, 113, 182, 0.747);
  transition: color 0.3s ease-in-out;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.send-btn:hover {
  color: rgb(28, 214, 69);
  transition: color 0.3s ease-in-out;
}

.send-btn.show {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
